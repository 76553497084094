'use client'
import { Link } from 'lib/i18n/routing'
import { useErrorPageAnalytics } from '@hashicorp/platform-analytics'
import use404Redirects from 'lib/hooks/use-404-redirects'
import style from './style.module.css'

interface FourOhFourProps {
	title?: string
	message?: string
	ctaLabel?: string
	ctaUrl?: string
}

const FourOhFour = ({
	// ! TODO Get translations for this message
	message,
	title = 'Page Not Found',
	ctaLabel = 'Back to Home',
	ctaUrl = '/',
}: FourOhFourProps) => {
	useErrorPageAnalytics(404)
	use404Redirects()

	return (
		<header className={style.main}>
			<h1 className={style.heading}>{title}</h1>
			{message ? <p>{message}</p> : null}
			<p>
				<Link href={ctaUrl}>{ctaLabel}</Link>
			</p>
		</header>
	)
}

export default FourOhFour
